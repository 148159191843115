import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { AuthResponse } from '../../models/authResponse';

@Component({
  selector: 'app-login-page',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './login-page.component.html',
  styleUrl: './login-page.component.scss'
})
export class LoginPageComponent {

  username: string = "";
  password: string = "";

  constructor(private auth: AuthService) {}

  doLogin() {
    this.auth.login(this.username,this.password).subscribe({
      next: (response: AuthResponse) => {
        localStorage.setItem("access", response.access);
        localStorage.setItem("refresh", response.refresh);
      },
      error: (error) => {
        console.error(error);
      }
    })
  }

}
