import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent {

  show: boolean = false;

  constructor(private auth: AuthService) { }

  isCurrentPage(path: string):boolean {

    if (location.pathname == path) {
      return true;
    }

    return false;
  }

  toogleCollapseNav() {
    this.show = !this.show;
  }

  isLogged() {
    return localStorage.getItem("access") != null
  }


}
