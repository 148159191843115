import { Routes } from '@angular/router';
import { CharacterSheetComponent } from './components/character-sheet/character-sheet.component';
import { DisciplinePageComponent } from './components/discipline-page/discipline-page.component';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { HomepageComponent } from './components/homepage/homepage.component';
import { MasterHomeComponent } from './components/master-home/master-home.component';
import { MasterCampaignComponent } from './components/master-campaign/master-campaign.component';

export const routes: Routes = [
  { path: 'home', component: HomepageComponent },
  { path: 'character', component: CharacterSheetComponent },
  { path: 'master', component: MasterHomeComponent },
  { path: 'master/campaign/:id', component: MasterCampaignComponent },
  { path: 'login', component: LoginPageComponent },
  { path: '', component: DisciplinePageComponent },
];
