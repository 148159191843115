import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PlayerService {

  constructor(private http: HttpClient) { }

  assegnaExp(pgIdList: number[], exp: number) {
    return this.http.post<String>(environment.baseUrl + "/master/character/exp", {
      pgIds: pgIdList,
      exp: exp
    })
  }
}
