<nav class="navbar navbar-expand-lg bg-body-tertiary">
  <div class="container-fluid">
    <a class="navbar-brand" routerLink = "/home">Navbar</a>
    <button class="navbar-toggler" type="button" (click)="toogleCollapseNav()" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div [class]="['collapse', 'navbar-collapse' , show ? 'show' : '']" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        @if(isLogged()) {
          <li class="nav-item">
            <a [class]="['nav-link', isCurrentPage('/master') ? 'active' : '']" routerLink = "master">Master</a>
          </li>
          <li class="nav-item">
            <a [class]="['nav-link', isCurrentPage('/player') ? 'active' : '']" routerLink = "player">PG</a>
          </li>
        }
        @else {
          <li class="nav-item">
            <a [class]="['nav-link', isCurrentPage('/login') ? 'active' : '']" routerLink = "login">Login</a>
          </li>

        }
        <li class="nav-item">
          <a [class]="['nav-link', isCurrentPage('/') ? 'active' : '']" routerLink = "">Discipline</a>
        </li>
    </ul>
    </div>
  </div>
</nav>
